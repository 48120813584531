<template>

  <div id="cropper_mod" class="modal fade" :class="showModal?'show':''">
    <div class="modal-dialog-broad" role="document">
      <div class="modal-content">
        <div class="modal-body-broad2">
          <h5 class="modal-title">{{$t('modalCropper.title')}}</h5>
          <div class="divider-wrap col-12">
            <div class="divider-middle mx-auto"></div>
          </div>
          <p class="modal-text mx-auto">{{$t('modalCropper.subtitle')}}</p>

    <div class="col-12 content_cropper" >
      <section class="cropper-area">
              <div class="img-container">
               <img src="images/picture.jpg" alt="Picture">
              </div>

          <div class="col-12 text-center bottom-zone force-no-padding">

              <div class="actions">
                <a
                  href="#"
                  role="button"
                  @click.prevent="zoom(0.2)"
                >
                  {{$t('academicResults.sectionOne.zoomIn')}}                  
                </a>
                <a
                  href="#"
                  role="button"
                  @click.prevent="zoom(-0.2)"
                >
                  {{$t('academicResults.sectionOne.zoomOut')}}
                </a>

                <a
                  href="#"
                  role="button"
                  @click.prevent="rotate(90)"
                >
                  {{$t('academicResults.sectionOne.rotate+90deg')}}          
                </a>
<br>
                <a
                  href="#"
                  role="button"
                  class="sec_button"
                  @click.prevent="cropImage"
                >
                  {{$t('academicResults.sectionOne.crop')}}                                  
                </a>

                <a
                  href="#"
                  role="button"
                  @click.prevent="modalCropperClose"
                  class="sec_button"
                >
                  {{$t('academicResults.sectionOne.button3')}}
                </a>

    </div>


            </div>
        </section>


        






          </div>

        </div>
      </div>
    </div>
  </div>


      <ModalServiceError v-if="serviceError" :service-error="serviceError" @close-modal-error="modalErrorClose" :literal="serviceErrorMsg" />


</template>

<script>

import { mapState } from 'vuex'
import 'cropperjs/dist/cropper.css';
import ModalServiceError from '@/components/ModalServiceError.vue'


export default {
  name: 'ModalCropper',
  components: {
      ModalServiceError
  },
  props: {
  },
  data() {
    return {
      showModal: false,
      selectChild: null,
      selectChildRequired: false,
      idAdmission: null,
      imgSrc:'',
      cropper:null,
      serviceError: false,
      serviceErrorMsg: null,
    }
  },
  computed: {
    ...mapState([
      'enviroments',
      'idAdmission',
      'step'
    ]),
    validateSelectChild() {
      return this.selectChild;
    },
  },
  watch: {
  },
  mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://fengyuanchen.github.io/cropperjs/js/cropper.js')
      document.head.appendChild(recaptchaScript);

  },
  methods: {

    loadImg(img)
    {
      this.imgSrc = img;

      var Cropper = window.Cropper;
      var container = document.querySelector('.img-container');
      var image = container.getElementsByTagName('img').item(0);
      var options = {
        viewMode:1,
        aspectRatio: 1 / 1,
        dragMode: 'move',
//        cropBoxResizable: false,
        toggleDragModeOnDblclick: false
      };
      
      image.src =img;

      if (this.cropper) 
      {this.cropper.destroy();}

      this.cropper = new Cropper(image, options);
      this.showModal=true;


    },

    modalCropperClose()
    {this.showModal = false;},

    showModalCropper()
    {
      this.showModal=true;
    },


    zoom(percent) {
      console.log(this.$refs.cropper);
      this.cropper.zoom(percent);
    },

    cropImage() {

      // get image data for post processing, e.g. upload or setting image src
      if (this.cropper.getCroppedCanvas()!=null)
      {
//        console.log(this.cropper.getCroppedCanvas().height);
//        console.log(this.cropper.getCroppedCanvas().width);
//        console.log(this.cropper.getCroppedCanvas().toDataURL('image/jpeg').length);

        if(this.cropper.getCroppedCanvas().height<640)
        {
              this.serviceError = true;
              this.serviceErrorMsg = this.$t('errors.minPhotoHeight',{'imgHeight': this.cropper.getCroppedCanvas().height}) ;
        }
        else if(this.cropper.getCroppedCanvas().width<144)
        {
              this.serviceError = true;
              this.serviceErrorMsg = this.$t('errors.minPhotoWidth',{'imgWidth': this.cropper.getCroppedCanvas().width}) ;
        }
        else if( this.cropper.getCroppedCanvas().toDataURL('image/jpeg').length<61440 || this.cropper.getCroppedCanvas().toDataURL('image/jpeg').length>1048576)
        {
              this.serviceError = true;
              this.serviceErrorMsg = this.$t('errors.minPhotoWeight',{'imgWeight': (this.cropper.getCroppedCanvas().toDataURL('image/jpeg').length/1024).toFixed(2)}) ;
        }
        else{
          this.$emit('imagecrppd', this.cropper.getCroppedCanvas().toDataURL('image/jpeg'));
          this.showModal = false;
        }
      
      }
//      this.cropImg = this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
    },
    rotate(deg) {
      this.cropper.rotate(deg);
    },
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }

      if (typeof FileReader === 'function') {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    modalErrorClose()
      {this.serviceError=false}



  }
}
</script>

<style>
.fade:not(.show) {
  display: none;
}
.fade.show {
  display: block;
}
</style>