  <template>
    <ModalCropper ref="modal1Cropper" @imagecrppd="imagecrppd"/>

    <div>
      <HeaderBar/>
      <Header steps="true" stepNumb="1"/>
      <Steps step_a="1"/>
      <div class="background-form">
        <div class="container">
          <div class="bloque-informacion-basica" id="formulario">
            <div class="row align-items-start">
              <div class="informacion-basica-titulo col">
                <h2 class="informacion-basica-texto">{{$t('basicInformation.title')}}</h2>
                <div class="divider-wrap">
                  <div class="divider-middle mx-auto"></div>
                </div>
              </div>	
              <h2 class="bloque-info col-12 text-center">{{$t('basicInformation.sectionOne.title')}}</h2>
            </div>
            <form @submit.prevent="saveAndContinue();scroll2error();" novalidate>
              <!-- <pre>{{v$.firstParent.email}}</pre> -->
              <div class="row align-items-start">
                <div class="col-12 col-md-6 offset-xl-1 col-xl-3">
                  <div class="form-group">
                    <label for="padre-madre-tutor" class="form-required">{{$t('basicInformation.sectionOne.tutor')}}</label>
                    <select v-model="firstParent.legal_guardian" class="form-control" :class="{'input-blue':firstParent.legal_guardian!==null}" :disabled="loggedParentTwo" id="padre-madre-tutor" >
                      <option value="1">{{$t('basicInformation.sectionOne.mother')}}</option>
                      <option value="2">{{$t('basicInformation.sectionOne.father')}}</option>
                      <option value="3">{{$t('basicInformation.sectionOne.legalGuardian')}}</option>
                    </select>
                    <div class="tooltip-error" v-if="v$.firstParent.legal_guardian.$error"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                  </div>
                </div>
                <div class="w-100 d-none d-xl-block"></div>
                <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                  <div class="form-group">
                    <label for="parent-nombre" class="form-required">{{$t('basicInformation.sectionOne.name')}}</label>
                    <input v-model="firstParent.name" @blur="v$.firstParent.name.$touch" class="form-control" :class="{'input-blue':firstParent.name!==null}" :disabled="loggedParentTwo" id="parent-nombre" rows="1">

                    <div class="tooltip-error" v-if="formchecked && v$.firstParent.name.required.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                    <div class="tooltip-error" v-if=" formchecked && v$.firstParent.name.maxLength.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 50</span>
                    </div>

                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-3">
                  <div class="form-group">
                    <label for="parent-1apellido" class="form-required">{{$t('basicInformation.sectionOne.firstname')}}</label>
                    <input v-model="firstParent.firstname" class="form-control" :class="{'input-blue':firstParent.firstname!==null}" :disabled="loggedParentTwo" id="parent-1apellido" rows="1">
                  
                    <div class="tooltip-error" v-if="formchecked && v$.firstParent.firstname.required.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                    <div class="tooltip-error" v-if=" formchecked && v$.firstParent.firstname.maxLength.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 50</span>
                    </div>

                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-3">
                  <div class="form-group">
                    <label for="parent-2apellido">{{$t('basicInformation.sectionOne.lastname')}}</label>
                    <input v-model="firstParent.lastname" class="form-control" :class="{'input-blue':firstParent.lastname!==null}" :disabled="loggedParentTwo" id="parent-2apellido" rows="1">
                  
                  <div class="tooltip-error" v-if=" formchecked && v$.firstParent.lastname.maxLength.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 50</span>
                    </div>
                  
                  </div>
                </div>


                <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                  <div class="form-group">
                    <label for="nationlity1-first" class="form-required">{{$t('basicInformation.sectionThree.nationality1')}}</label>
                    <select v-model="firstParent.current_nationality.id" class="form-control" :class="{'input-blue':firstParent.current_nationality && firstParent.current_nationality.id!==null}" id="nationality1-first">
                      <option v-for="(nationality, index) in nationalities" :key="index" :value="nationality.nationalityid">{{nationality.name}}</option>
                    </select> 
                    <div class="tooltip-error" v-if="formchecked && v$.firstParent.current_nationality.id.$error"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                  </div>                
                </div>


                <div class="w-100 d-none d-xl-block"></div>
                <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                  <div class="form-group">
                    <label for="parent-pais" class="form-required">{{$t('basicInformation.sectionOne.country')}}</label>
                    <select v-model="firstParent.country" class="form-control" :class="{'input-blue':firstParent.country!==null}" :disabled="loggedParentTwo" id="parent-pais">
                      <option v-for="(country, index) in countries" :key="index" :value="country.mec_code">{{country.nombre}}</option>
                    </select>
                    <div class="tooltip-error" v-if="v$.firstParent.country.$error"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                  </div>
                </div>


                  <template v-if="provinces_firstParent.length>1">
                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="form-group">
                      <label for="parent-province" class='form-required'>{{$t('basicInformation.sectionOne.province')}}</label>
                      <select v-model="firstParent.province" class="form-control" :class="{'input-blue':firstParent.province!==''}" id="parent-province">
                        <option v-for="(province, index) in provinces_firstParent" :key="index" :value="province.id">{{province.name}}</option>
                      </select>
                      <div class="tooltip-error" v-if="v$.firstParent.province.$error"> 
                        <div class="arrow"></div>
                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                      </div>
                    </div>
                  </div>
                  </template>
                
                <div class="col-12 col-md-6 col-xl-3">
                  <div class="form-group">
                    <label for="parent-ciudad" class="form-required">{{$t('basicInformation.sectionOne.city')}}</label>
                    <input v-model="firstParent.city" class="form-control" :class="{'input-blue':firstParent.city!==null}" :disabled="loggedParentTwo" id="parent-ciudad">

                    <div class="tooltip-error" v-if="formchecked && v$.firstParent.city.required.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                    <div class="tooltip-error" v-if=" formchecked && v$.firstParent.city.maxLength.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 80</span>
                    </div>
                  </div>
                </div>


                <div class="w-100 d-none d-xl-block"></div>
                <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                  <div class="form-group">
                    <label for="parent-pais" class="form-required">{{$t('basicInformation.sectionOne.tel_country')}}</label>
                    <select v-model="firstParent.tel_country_mec" class="form-control" :class="{'input-blue':firstParent.tel_country_mec!==null}" :disabled="loggedParentTwo" id="parent-pais" @change="selectCountry1">
                      <option v-for="(country, index) in countries" :key="index" :value="country.mec_code" :data-prefix="country.phone_code" :data-name="country.nombre">{{country.nombre}}</option>
                    </select>
                    <div class="tooltip-error" v-if="v$.firstParent.tel_country_mec.$error"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-3">
                  <div class="row">
                    <div class="form-group col-4 force-no-padding-right">
                      <label for="prefix">{{$t('basicInformation.sectionOne.prefix')}}</label>
                      <input type="tel" class="form-control" :class="{'input-blue':firstParent.tel_country_prefix!==null}" disabled id="prefix" name="phone" :placeholder="'+' + [[ firstParent.tel_country_prefix ]]">
                    </div>
                    <div class="form-group col-8 force-no-padding-left">
                      <label for="phone" class="form-required">{{$t('basicInformation.sectionOne.phone')}}</label>
                      <input v-model="firstParent.phone_number" type="tel" class="form-control" :class="{'input-blue':firstParent.phone_number!==null}" :disabled="loggedParentTwo" id="phone" name="phone">
                      
                      <div class="tooltip-error" v-if="formchecked && (v$.firstParent.phone_number.numeric.$invalid || v$.firstParent.phone_number.required.$invalid)"> 
                        <div class="arrow"></div>
                        <span class="tooltip-text"><span v-if="v$.firstParent.phone_number.required.$invalid">{{ $t('errors.fieldRequired')}}</span><span v-if="v$.firstParent.phone_number.numeric.$invalid">{{$t('errors.fieldNumeric')}}</span></span>
                      </div>
                      <div class="tooltip-error" v-if=" formchecked && v$.firstParent.phone_number.maxLength.$invalid">
                        <div class="arrow"></div>
                        <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 50</span>
                      </div>
                      
                    </div>
                  </div>
                </div>
                
                <div class="w-100 d-none d-xl-block"></div>
                <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                  <div class="form-group">
                    <label for="how-met" class="form-required">{{$t('basicInformation.sectionTwo.howMet')}}</label>
                    <select v-model="firstParent.how_met" class="form-control" :class="{'input-blue':firstParent.how_met!==null&&firstParent.how_met!==''}" id="how-met">
                      <option v-for="(howMet, index) in howMetOptions" :key="index" :value="howMet.valor">{{howMet.name}}</option>
                    </select>
                    <div class="tooltip-error" v-if="formchecked && v$.firstParent.how_met.$error"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>

                  </div>
                </div>

                <div v-if="firstParent.how_met == 141180015" class="col-12 col-md-6 col-xl-3">
                  <div class="form-group">
                    <label for="how-met-other" class="form-required">{{$t('basicInformation.sectionTwo.howMetOtherPlaceholder')}}</label>
                    <input v-model="firstParent.how_met_other" class="form-control" :class="{'input-blue':firstParent.how_met_other}" id="how-met-other" rows="1">

                    <div class="tooltip-error" v-if="formchecked && firstParent.how_met == 141180015 && ( firstParent.how_met_other == null || firstParent.how_met_other.trim() == '')">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>

                  </div>
                </div>


                <div class="col-12 col-md-12 col-xl-6" :class="{'offset-xl-1':firstParent.how_met == 141180015}">
                  <div class="form-group">
                    <label for="parent-profession" class="form-required">{{$t('basicInformation.sectionOne.profession')}}</label>
                    <input v-model="firstParent.profession" class="form-control" :class="{'input-blue':firstParent.profession!==null}" :disabled="loggedParentTwo" id="parent-profession">

                    <div class="tooltip-error" v-if="formchecked && v$.firstParent.profession.required.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>

                  </div>
                </div>


                <div class="col-12 col-md-12 offset-xl-1 col-xl-10">
                      <div class="form-group">
                          <label for="firstParentes-textarea" class="long_label">{{$t('basicInformation.sectionOne.participate')}}</label>
                          <textarea v-model.trim="firstParent.participate" maxlength="4000" class="form-control textarea2" :class="{'input-blue':firstParent.participate!==null}" id="firstParentes-textarea" rows="2"></textarea>
                      </div>
                  </div>


              
              <div class="w-100 d-none d-xl-block"></div>

                <div class="col-12 text-center-mobile offset-xl-1 col-xl-4"> 
                  <a v-if="!addParent" href="" @click="addParent = !addParent" class="btn btn-primary btn-formulario otro-parent" data-toggle="collapse" data-target="#otro-parent" aria-expanded="false" aria-controls="otro-parent"> 
                    {{$t('basicInformation.sectionOne.button')}}
                  </a>
                </div>

                <div class="collapse" id="otro-parent">
                  <div class="container">
                    <div class="row">
                      <div class=" col-12 offset-xl-1 col-xl-10">
                        <div class="container-delete-parent" >
                          <a @click="deleteSecondParent()" v-if="!loggedParentTwo" class="img-delete" ><img src="@/assets/img/delete_block_parent.png" alt="" class="float-right"></a>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                        <div class="form-group">
                          <label for="email2" class="form-required">{{$t('basicInformation.sectionOne.email')}}</label>
                          <input v-model="secondParent.email" @blur="checkEmailParentTwo" type="email" class="form-control" :class="{'input-blue':secondParent.email!==null}" id="email2" placeholder="" :disabled="loggedParentTwo || disableParentTwo">
                          
                          <div class="tooltip-error" v-if="formchecked && this.addParent && v$.secondParent.email.required.$invalid"> 
                            <div class="arrow"></div>
                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                          </div>
                          <div class="tooltip-error" v-if="formchecked && this.addParent && v$.secondParent.email.email.$invalid"> 
                            <div class="arrow"></div>
                            <span class="tooltip-text">{{$t('errors.email')}}</span>
                          </div>
                          <div class="tooltip-error" v-if="formchecked && this.addParent && v$.secondParent.email.maxLength.$invalid">
                            <div class="arrow"></div>
                            <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 100</span>
                          </div>
                          <div class="tooltip-error" v-if="secondParent.emailDuplicated"> 
                            <div class="arrow"></div>
                            <span class="tooltip-text">{{$t('errors.emailSecondParentDuplicated')}}</span>
                          </div>

                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-3">
                        <div class="form-group ">
                          <label for="padre-madre-tutor2" class="form-required">{{$t('basicInformation.sectionOne.tutor')}}</label>
                          <select v-model="secondParent.legal_guardian" class="form-control" :class="{'input-blue':secondParent.legal_guardian!==null}" id="padre-madre-tutor2">
                            <option value="1">{{$t('basicInformation.sectionOne.mother')}}</option>
                            <option value="2">{{$t('basicInformation.sectionOne.father')}}</option>
                            <option value="3">{{$t('basicInformation.sectionOne.legalGuardian')}}</option>
                          </select>
                          <div class="tooltip-error" v-if="this.addParent && v$.secondParent.legal_guardian.$error"> 
                            <div class="arrow"></div>
                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="w-100 d-none d-xl-block"></div>
                      <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                        <div class="form-group">
                          <label for="parent-nombre2" class="form-required">{{$t('basicInformation.sectionOne.name')}}</label>
                          <input v-model="secondParent.name" class="form-control" :class="{'input-blue':secondParent.name!==null}" id="parent-nombre2" rows="1">

                        <div class="tooltip-error" v-if="this.addParent && formchecked && v$.secondParent.name.required.$invalid">
                          <div class="arrow"></div>
                          <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                        </div>
                        <div class="tooltip-error" v-if="this.addParent && formchecked && v$.secondParent.name.maxLength.$invalid">
                          <div class="arrow"></div>
                          <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 50</span>
                        </div>

                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-3">
                        <div class="form-group">
                          <label for="parent-1apellido2" class="form-required">{{$t('basicInformation.sectionOne.firstname')}}</label>
                          <input v-model="secondParent.firstname" class="form-control" :class="{'input-blue':secondParent.firstname!==null}" id="parent-1apellido2" rows="1">
                          
                        <div class="tooltip-error" v-if="this.addParent && formchecked && v$.secondParent.firstname.required.$invalid">
                          <div class="arrow"></div>
                          <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                        </div>
                        <div class="tooltip-error" v-if="this.addParent && formchecked && v$.secondParent.firstname.maxLength.$invalid">
                          <div class="arrow"></div>
                          <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 50</span>
                        </div>

                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-3">
                        <div class="form-group">
                          <label for="parent-2apellido2">{{$t('basicInformation.sectionOne.lastname')}}</label>
                          <input v-model="secondParent.lastname" class="form-control" :class="{'input-blue':secondParent.lastname!==null}" id="parent-2apellido2" rows="1">
                        
                        <div class="tooltip-error" v-if="this.addParent && formchecked && v$.secondParent.lastname.maxLength.$invalid">
                          <div class="arrow"></div>
                          <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 50</span>
                        </div>

                        
                        </div>
                      </div>


                <div class="col-12 col-md-6 offset-xl-1 col-xl-4" v-if="this.addParent">
                  <div class="form-group">
                    <label for="nationlity1-second" class="form-required">{{$t('basicInformation.sectionThree.nationality1')}}</label>
                    <select v-model="secondParent.current_nationality.id" class="form-control" :class="{'input-blue':secondParent.current_nationality && secondParent.current_nationality.id!==null}" id="nationality1-second">
                      <option v-for="(nationality, index) in nationalities" :key="index" :value="nationality.nationalityid">{{nationality.name}}</option>
                    </select> 
                    <div class="tooltip-error" v-if="this.addParent && formchecked &&  v$.secondParent.current_nationality.id.$error"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                  </div>                
                </div>


                      <div class="w-100 d-none d-xl-block"></div>


                      <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                        <div class="form-group">
                          <label for="parent-pais2" class="form-required">{{$t('basicInformation.sectionOne.country')}}</label>
                          <select v-model="secondParent.country" class="form-control" :class="{'input-blue':secondParent.country!==null}" id="parent-pais2">
                            <option v-for="(country, index) in countries" :key="index" :value="country.mec_code">{{country.nombre}}</option>
                          </select>
                          <div class="tooltip-error" v-if="this.addParent && v$.secondParent.country.$error"> 
                            <div class="arrow"></div>
                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                          </div>
                        </div>
                      </div>
                

                  <template v-if="provinces_secondParent.length>1">
                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="form-group">
                      <label for="parent-province2" class='form-required'>{{$t('basicInformation.sectionOne.province')}}</label>
                      <select v-model="secondParent.province" class="form-control" :class="{'input-blue':secondParent.province!==''}" id="parent-province2">
                        <option v-for="(province, index) in provinces_secondParent" :key="index" :value="province.id">{{province.name}}</option>
                      </select>
                      <div class="tooltip-error" v-if="this.addParent && v$.secondParent.province.$error"> 
                        <div class="arrow"></div>
                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                      </div>
                    </div>
                  </div>
                  </template>





                      <div class="col-12 col-md-6 col-xl-3">
                        <div class="form-group">
                          <label for="parent-ciudad2" class="form-required">{{$t('basicInformation.sectionOne.city')}}</label>
                          <input v-model="secondParent.city" class="form-control" :class="{'input-blue':secondParent.city!==null}" id="parent-ciudad2"> 

                            <div class="tooltip-error" v-if="this.addParent && formchecked && v$.secondParent.city.required.$invalid">
                              <div class="arrow"></div>
                              <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                            </div>
                            <div class="tooltip-error" v-if="this.addParent && formchecked && v$.secondParent.city.maxLength.$invalid">
                              <div class="arrow"></div>
                              <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 80</span>
                            </div>

                        </div>
                      </div>


                      <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                        <div class="form-group">
                          <label for="parent-pais2" class="form-required">{{$t('basicInformation.sectionOne.tel_country')}}</label>
                          <select v-model="secondParent.tel_country_mec" class="form-control" :class="{'input-blue':secondParent.tel_country_mec!==null}" id="parent-pais2" @change="selectCountry2">
                             <option v-for="(country, index) in countries" :key="index" :value="country.mec_code" :data-prefix="country.phone_code" :data-name="country.nombre">{{country.nombre}}</option>
                          </select>
                          <div class="tooltip-error" v-if="this.addParent && v$.secondParent.tel_country_mec.$error"> 
                            <div class="arrow"></div>
                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-xl-3">
                        <div class="row">
                          <div class="form-group col-4 force-no-padding-right">
                            <label for="prefix2">{{$t('basicInformation.sectionOne.prefix')}}</label>
                            <input type="tel" class="form-control" :class="{'input-blue':secondParent.tel_country_prefix!==null}" disabled id="prefix" name="phone" :placeholder="'+' + [[ secondParent.tel_country_prefix ]]">
                          </div>
                          <div class="form-group col-8 force-no-padding-left">
                            <label for="phone2" class="form-required">{{$t('basicInformation.sectionOne.phone')}}</label>
                            <input v-model="secondParent.phone_number" type="tel" class="form-control" :class="{'input-blue':secondParent.phone_number!==null}" id="phone" name="phone">
                            
                            <div class="tooltip-error" v-if="this.addParent && formchecked && (v$.secondParent.phone_number.$dirty && (v$.secondParent.phone_number.numeric.$invalid || v$.secondParent.phone_number.required.$invalid))"> 
                              <div class="arrow"></div>
                              <span class="tooltip-text"><span v-if="v$.secondParent.phone_number.$dirty && v$.secondParent.phone_number.required.$invalid">{{ $t('errors.fieldRequired')}}</span><span v-if="v$.secondParent.phone_number.numeric.$invalid">{{$t('errors.fieldNumeric')}}</span></span>
                            </div>
                            <div class="tooltip-error" v-if="this.addParent && formchecked && v$.secondParent.phone_number.maxLength.$invalid">
                              <div class="arrow"></div>
                              <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 50</span>
                            </div>

                          </div>
                        </div>
                      </div>



                <div class="col-12 col-md-12 offset-xl-1 col-xl-7">
                  <div class="form-group">
                    <label for="parent-professio-2" class="form-required">{{$t('basicInformation.sectionOne.profession')}}</label>
                    <input v-model="secondParent.profession" class="form-control" :class="{'input-blue':secondParent.profession!==null}" :disabled="loggedParentTwo" id="parent-profession-2">

                    <div class="tooltip-error" v-if="this.addParent && formchecked && v$.secondParent.profession.required.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>

                  </div>
                </div>



                    </div>
                  </div>
                </div>
                <div class="w-100 d-none d-xl-block"></div>

                <h2 class="bloque-info col-12 text-center">{{$t('basicInformation.sectionTwo.title')}}</h2>
                <div class="w-100 d-none d-xl-block"></div>

                <div class="col-12 col-md-3 offset-xl-1 col-xl-3" v-if="!idAdmission && childrenSel.length">
                  <div class="form-group">
                    <label for="padre-madre-tutor"></label>
                    <select class="form-control" @change="changeChild($event)" id="select-child" >
                      <option value="0">{{$t('basicInformation.sectionTwo.select_one')}}</option>
                      <option v-for="(childSel, index) in childrenSel" :key="index" :value="childSel.contactId">{{childSel.name}} {{childSel.firstname}} {{childSel.lastname}}</option>

                    </select>
                    <div class="tooltip-error" v-if="v$.firstParent.legal_guardian.$error"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                  </div>
                </div>
                
                <div class="col-12 col-md-8 col-xl-7" v-if="!idAdmission && childrenSel.length">
                  <p class="col-12 bloque-info row" style="margin-top:23px"><span><i>{{$t('basicInformation.sectionTwo.select_one_text')}}</i></span></p>
                </div>

                <div class="w-100 d-none d-xl-block"></div>


<!--                <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                  <div class="form-group">
                    
                  <label for="alumno-mail" class="form-required">{{$t('basicInformation.sectionTwo.mail')}}<sup>1</sup></label>
                  <input v-model="student.email" class="form-control" :class="{'input-blue':student.email!=null }" id="alumno-mail" rows="1" @blur="checkEmailStudent">
                  
                  <div class="tooltip-error" v-if="formchecked && v$.student.email.required.$invalid">
                    <div class="arrow"></div>
                    <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                  </div>
                  <div class="tooltip-error" v-if=" formchecked && v$.student.email.email.$invalid">
                    <div class="arrow"></div>
                    <span class="tooltip-text">{{$t('errors.email')}}</span>
                  </div>
                  <div class="tooltip-error" v-if="student.emailDuplicated"> 
                    <div class="arrow"></div>
                    <span class="tooltip-text">{{$t('errors.emailStudentDuplicated')}}</span>
                  </div>



                </div>
              </div>
              <div class="col-12 col-md-6 col-xl-3">
                <div class="form-group">

                    <label for="gender" class="form-required">{{$t('basicInformation.sectionTwo.gendercode')}}</label>
                    <div class="row">
                      <label class="checkbox-container form-check col-4 ">{{$t('basicInformation.sectionTwo.male')}}
                        <input v-model="student.gendercode" value="1" type="radio" name="gender">
                        <span class="checkmark"></span>
                      </label>
                      <label class="checkbox-container form-check col-4 ">{{$t('basicInformation.sectionTwo.female')}}
                        <input v-model="student.gendercode" value="2" type="radio" name="gender">
                        <span class="checkmark"></span>
                      </label>
                      <div class="tooltip-error" v-if="v$.student.gendercode.$error"> 
                        <div class="arrow"></div>
                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-100 d-none d-xl-block"></div>-->

                <div class="col-12 col-md-6 offset-xl-1 col-xl-4 force-no-padding-left">


                    <div class="col-12">
                      <div class="form-group">
                        
                      <label for="alumno-mail" class="form-required">{{$t('basicInformation.sectionTwo.mail')}}<sup>1</sup></label>
                      <input v-model="student.email" class="form-control" :class="{'input-blue':student.email!=null }" id="alumno-mail" rows="1" @blur="checkEmailStudent">
                      
                      <div class="tooltip-error" v-if="formchecked && v$.student.email.required.$invalid">
                        <div class="arrow"></div>
                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                      </div>
                      <div class="tooltip-error" v-if=" formchecked && v$.student.email.email.$invalid">
                        <div class="arrow"></div>
                        <span class="tooltip-text">{{$t('errors.email')}}</span>
                      </div>
                      <div class="tooltip-error" v-if="student.emailDuplicated"> 
                        <div class="arrow"></div>
                        <span class="tooltip-text">{{$t('errors.emailStudentDuplicated')}}</span>
                      </div>



                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">

                        <label for="gender" class="form-required">{{$t('basicInformation.sectionTwo.gendercode')}}</label>
                        <div class="row">
                          <label class="checkbox-container form-check col-4 ">{{$t('basicInformation.sectionTwo.male')}}
                            <input v-model="student.gendercode" value="1" type="radio" name="gender">
                            <span class="checkmark"></span>
                          </label>
                          <label class="checkbox-container form-check col-4 ">{{$t('basicInformation.sectionTwo.female')}}
                            <input v-model="student.gendercode" value="2" type="radio" name="gender">
                            <span class="checkmark"></span>
                          </label>
                          <div class="tooltip-error" v-if="v$.student.gendercode.$error"> 
                            <div class="arrow"></div>
                            <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                          </div>
                        </div>
                      </div>
                    </div>


                              
                    <div class="col-12">
                      <div class="form-group">
                        <label for="alumno-nombre" class="form-required">{{$t('basicInformation.sectionTwo.name')}}</label>
                        <input v-model="student.name" class="form-control" :class="{'input-blue':student.name!==null}" id="alumno-nombre" rows="1">
                        
                        <div class="tooltip-error" v-if="formchecked && v$.student.name.required.$invalid">
                          <div class="arrow"></div>
                          <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                        </div>
                        <div class="tooltip-error" v-if=" formchecked && v$.student.name.maxLength.$invalid">
                          <div class="arrow"></div>
                          <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 50</span>
                        </div>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label for="alumno-1apellido" class="form-required">{{$t('basicInformation.sectionTwo.firstname')}}</label>
                        <input v-model="student.firstname" class="form-control" :class="{'input-blue':student.firstname!==null}" id="alumno-1apellido" rows="1">
                      
                        <div class="tooltip-error" v-if="formchecked && v$.student.firstname.required.$invalid">
                          <div class="arrow"></div>
                          <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                        </div>
                        <div class="tooltip-error" v-if=" formchecked && v$.student.firstname.maxLength.$invalid">
                          <div class="arrow"></div>
                          <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 50</span>
                        </div>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label for="alumno-2apellido">{{$t('basicInformation.sectionTwo.lastname')}}</label>
                        <input v-model="student.lastname" class="form-control" :class="{'input-blue':student.lastname!==null&&student.lastname!==''}" id="alumno-2apellido" rows="1">
                      
                        <div class="tooltip-error" v-if=" formchecked && v$.student.lastname.maxLength.$invalid">
                          <div class="arrow"></div>
                          <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 50</span>
                        </div>                
                      
                      </div>
                    </div>

                </div>



                <div class="col-12 col-md-6 col-xl-3">

                              <label for="student_photo_" class="form-required">{{$t('basicInformation.sectionTwo.attachPhoto')}}</label> 
                              <span class='tooltip_container'><img src='@/assets/img/i_blue.svg' class='info-icon-2'><span class='tooltiptext wide_tooltip' v-html="$t('basicInformation.sectionTwo.additionalDocumentation_1')"></span></span>

                              <div class="photo_box">
                                <div class="menu-formulario-img">
                                    <img src="@/assets/img/photo.png" class="" alt="" v-if="student_photo_thumb==''" >
                                    <img :src="student_photo_thumb" class="" alt=""  v-if="student_photo_thumb!=''">
                                </div>
                                <div class="menu-formulario-text">
                                  <div class="menu-formulario-title">
                                    <input ref="imageUploader" @change="loadFile($event, 'student_photo')" @click="resetFile($event, 'student_photo')" type="file" class="form-control-file d-none" id="student_photo_">
                                    <label for="student_photo_" class="btn btn-primary btn-formulario">
                                      {{$t('academicResults.sectionOne.button_')}}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div class="col-12 photo_label">
                                <label class="long_label" v-html="$t('basicInformation.sectionTwo.photo_label')"></label>
                              </div>



<!--                              <div class="tooltip-error" v-if="formchecked && v$.student.student_photo.$dirty && v$.student.student_photo.name.required.$invalid">
                                  <div class="arrow"></div>
                                  <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                              </div>-->

                              <div class="tooltip-error" v-if="this.student.student_photo.name !== null && this.student.student_photo.name !== '' && v$.student.student_photo.name.extension.$invalid">
                                  <div class="arrow"></div>
                                  <span class="tooltip-text">{{$t('errors.fileExtensionErrorJPG')}}</span>
                              </div>

                                <div class="tooltip-error" v-if="this.student.student_photo.file !== null && v$.student.student_photo.file.file_size_validation.$invalid">
                                  <div class="arrow"></div>
                                  <span class="tooltip-text">{{$t('errors.fileSizeError2')}}</span>
                              </div>

                </div>

                





                <div class="w-100 d-none d-xl-block d-md-block"></div>



                <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                  <div class="form-group"> 
                    <p class="form-required label-text label-files" v-html="$t('basicInformation.sectionTwo.dateOfBirth')"></p>
                      <v-date-picker v-model="dateOfBirth">
                        <template v-slot="{togglePopover}">
                          <div class="row">
                            <div class="col-3">
                              <label for="date" class="d-none">día</label>
                              <input v-model="date_of_birth.day" type="text" id="date" name="date" class="form-control" placeholder="dd" :class="{'input-blue':student.date_of_birth!==null}" @blur="updateStudentBirth">
                            </div>
                            <div class="col-3">
                              <label for="month" class="d-none">mes</label>
                              <input v-model="date_of_birth.month" type="text" id="month" name="month" class="form-control" placeholder="mm" :class="{'input-blue':student.date_of_birth!==null}" @blur="updateStudentBirth">
                            </div>
                            <div class="col-3">
                              <label for="year" class="d-none">año</label>
                              <input v-model="date_of_birth.year" type="text" id="year" name="year" class="form-control" placeholder="yyyy" :class="{'input-blue':student.date_of_birth!==null}" @blur="updateStudentBirth">
                            </div>
                            <div class="col-3">
                              <button type="button" @click="togglePopover()" class="border-0 button-cal">
                                <img src="@/assets/img/icon-calendar.svg" alt="">
                              </button>
                            </div>
                            <div class="tooltip-error" v-if="v$.student.date_of_birth.$error"> 
                              <div class="arrow"></div>
                              <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                            </div>
                            <div class="tooltip-error" v-if="!v$.student.date_of_birth.$error && student.date_of_birth_error "> 
                              <div class="arrow"></div>
                              <span class="tooltip-text">{{$t('errors.fieldDateLimit')}}</span>
                            </div>
                          </div>
                        </template>
                      </v-date-picker>
                  </div>
                </div>

              <div class="col-12 col-md-6 col-xl-3">
                <div class="form-group">
                  <label for="alumno-lugar-nacimiento" class="form-required">{{$t('basicInformation.sectionTwo.birthplace')}}</label>
                  <input v-model="student.place_of_birth" class="form-control" :class="{'input-blue':(student.place_of_birth!==''&&student.place_of_birth!==null)}" id="alumno-lugar-nacimiento" rows="1">
                  <div class="tooltip-error" v-if="formchecked && v$.student.place_of_birth.required.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                  </div>
                </div>
              </div>

              <div class="w-100"></div>


              <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                  <div class="form-group">
                    <label for="anio-academico" class="form-required">{{$t('basicInformation.sectionTwo.academicYear')}}</label>
                    <select v-model="student.academic_year.id" class="form-control" :class="{'input-blue':student.academic_year.id!==null&&student.academic_year.id!==''}" id="anio-academico">
                      <option v-for="(academicYear, index) in academicYears" :key="index" :value="academicYear.id">{{academicYear.name}}</option>
                    </select>
                    <div class="tooltip-error" v-if="v$.student.academic_year.id.$error"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-xl-3">
                  <div class="form-group">
                    <label for="interested" class="form-required">{{$t('basicInformation.sectionTwo.interested')}}</label>
                    <div class="row">
                      <label class="checkbox-container form-check col-4 ">{{$t('basicInformation.sectionTwo.day')}}
                        <input v-model="student.interested" value="141180000" type="radio" name="interested" checked>
                        <span class="checkmark"></span>
                      </label>
                      <label class="checkbox-container form-check col-4 ">{{$t('basicInformation.sectionTwo.boarding')}}
                        <input v-model="student.interested" value="141180001" type="radio" name="interested">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
        
                <div class="w-100"></div>

                <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                  <div class="form-group">
                    <label for="alumno-pais" class="form-required">{{$t('basicInformation.sectionTwo.country')}}</label>
                    <select v-model="student.country" class="form-control" :class="{'input-blue':student.country!==null&&student.country!==''}" id="alumno-pais">
                      <option v-for="(country, index) in countries" :key="index" :value="country.mec_code">{{country.nombre}}</option>
                    </select> 
                    <div class="tooltip-error" v-if="v$.student.country.$error"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                  </div>
                </div>


                  <template v-if="provinces_student.length>1">
                  <div class="col-12 col-md-6 col-xl-3">
                    <div class="form-group">
                      <label for="alumno-province" class='form-required'>{{$t('basicInformation.sectionOne.province')}}</label>
                      <select v-model="student.province" class="form-control" :class="{'input-blue':student.province!==''}" id="alumno-province">
                        <option v-for="(province, index) in provinces_student" :key="index" :value="province.id">{{province.name}}</option>
                      </select>
                      <div class="tooltip-error" v-if="v$.student.province.$error"> 
                        <div class="arrow"></div>
                        <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                      </div>
                    </div>
                  </div>
                  </template>



                <div class="col-12 col-md-6 col-xl-3">
                  <div class="form-group">
                    <label for="alumno-ciudad" class="form-required">{{$t('basicInformation.sectionTwo.city')}}</label>
                    <input v-model="student.city" class="form-control" :class="{'input-blue':student.city!==null}" id="alumno-ciudad">
                    
                    <div class="tooltip-error" v-if="formchecked && v$.student.city.required.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                    <div class="tooltip-error" v-if=" formchecked && v$.student.city.maxLength.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 80</span>
                    </div>
                    
                  </div>
                </div>
                
                <div class="w-100"></div>

                <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                  <div class="form-group">
                    <label for="nationlity1-ahora" class="form-required">{{$t('basicInformation.sectionThree.nationality1')}}</label>
                    <select v-model="student.current_nationality1.id" class="form-control" :class="{'input-blue':student.current_nationality1.id!==null}" id="nationality1-ahora" @change="selectNationality1($event)">
                      <option v-for="(nationality, index) in nationalities" :key="index" :value="nationality.nationalityid">{{nationality.name}}</option>
                    </select> 
                    <div class="tooltip-error" v-if="v$.student.current_nationality1.id.$error"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                    <div class="tooltip-error" v-if="formchecked && student.current_nationality1.id!==null && student.current_nationality2.id!==null && student.current_nationality2.id === student.current_nationality1.id"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.sameNationalityError')}}</span>
                    </div>  
                  </div>                
                </div>

                <div class="col-12 col-md-6 col-xl-3">
                  <div class="form-group">
                    <label for="alumno-dni-type" class="form-required">{{$t('basicInformation.sectionTwo.passportType')}}</label>
                    <select v-model="student.current_nationality1.passport_type" class="form-control" :class="{'input-blue':student.current_nationality1.passport_type}" id="alumno-dni-type">
                            <option value="141180000" v-if=" !student.current_nationality1.restricted">{{$t('basicInformation.sectionTwo.passportType1')}}</option>
                            <option value="141180002" v-if=" !student.current_nationality1.restricted">{{$t('basicInformation.sectionTwo.passportType3')}}</option>
                            <option value="141180001">{{$t('basicInformation.sectionTwo.passportType2')}}</option>

                    </select>
                    <div class="tooltip-error" v-if="v$.student.current_nationality1.passport_type.$error"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-3">
                  <div class="form-group">
                    <label for="alumno-dni" class="form-required">{{$t('basicInformation.sectionTwo.passportNumber')}}</label>
                    <input v-model="student.current_nationality1.passport_number" class="form-control" :class="{'input-blue':student.current_nationality1.passport_number}" id="alumno-dni" rows="1">
                    
                    <div class="tooltip-error" v-if="formchecked && v$.student.current_nationality1.passport_number.required.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                    <div class="tooltip-error" v-if=" formchecked && v$.student.current_nationality1.passport_number.maxLength.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldMaxlength')}} 100</span>
                    </div>

                    <div class="tooltip-error" v-if=" formchecked && student.current_nationality1.number_error">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldDNIFormat')}}</span>
                    </div>


                  </div>
                </div>

                <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                  <div class="form-group">
                    <label for="nationlity2-ahora" :class="{'form-required':(student.current_nationality2.id && student.current_nationality2.id !== '0')}">{{$t('basicInformation.sectionThree.nationality2')}}</label>
                    <select v-model="student.current_nationality2.id" class="form-control" :class="{'input-blue':student.current_nationality2.id!==null}" id="nationality2-ahora" @change="selectNationality2($event)">
                      <option value="0">{{$t('basicInformation.sectionThree.selectNationality2')}}</option>
                      <option v-for="(nationality, index) in nationalities" :key="index" :value="nationality.nationalityid">{{nationality.name}}</option>
                    </select> 
                    <div class="tooltip-error" v-if="formchecked && student.current_nationality1.id!==null && student.current_nationality2.id!==null && student.current_nationality2.id === student.current_nationality1.id"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.sameNationalityError')}}</span>
                    </div>                
                  </div> 
                </div>

                <div class="col-12 col-md-6 col-xl-3">
                  <div class="form-group">
                    <label for="alumno-dni-type" :class="{'form-required':(student.current_nationality2.id && student.current_nationality2.id !== '0')}">{{$t('basicInformation.sectionTwo.passportType')}}</label>
                    <select v-model="student.current_nationality2.passport_type" class="form-control" :class="{'input-blue':student.current_nationality2.passport_type}" id="alumno-dni-type" :disabled="student.current_nationality2.id === '0'">
                            <option value="141180000" v-if=" !student.current_nationality2.restricted">{{$t('basicInformation.sectionTwo.passportType1')}}</option>
                            <option value="141180002" v-if=" !student.current_nationality2.restricted">{{$t('basicInformation.sectionTwo.passportType3')}}</option>
                            <option value="141180001">{{$t('basicInformation.sectionTwo.passportType2')}}</option>
                    </select>
                    <div class="tooltip-error" v-if="formchecked && student.current_nationality2.id && student.current_nationality2.id!== '0' && !student.current_nationality2.passport_type"> 
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6 col-xl-3">
                  <div class="form-group">
                    <label for="alumno-dni" :class="{'form-required':(student.current_nationality2.id && student.current_nationality2.id !== '0')}">{{$t('basicInformation.sectionTwo.passportNumber')}}</label>
                    <input v-model="student.current_nationality2.passport_number" class="form-control" :class="{'input-blue':student.current_nationality2.passport_number}" id="alumno-dni" rows="1" :disabled="student.current_nationality2.id === '0'">
                    
                    <div class="tooltip-error" v-if="formchecked && student.current_nationality2.id && student.current_nationality2.id !== '0' && !student.current_nationality2.passport_number">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>

                    <div class="tooltip-error" v-if="formchecked && student.current_nationality2.id && student.current_nationality2.id !== '0' && student.current_nationality2.passport_number && student.current_nationality2.passport_number.includes(' ')">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequiredWhitespace')}}</span>
                    </div>

                    <div class="tooltip-error" v-if=" formchecked && student.current_nationality2.number_error">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldDNIFormat')}}</span>
                    </div>



                  </div>
                </div>


                <div class="w-100"></div>
                <div class="col-12 col-md-6 offset-xl-1 col-xl-2">
                  <div class="form-group">
                    <label for="visa-needed" class="form-required">{{$t('basicInformation.sectionTwo.visaNeeded')}}</label>
                    <div class="row">
                      <label class="checkbox-container form-check col-4 yes_no">{{$t('basicInformation.sectionTwo.yes')}}
                        <input v-model="student.visa_needed.checked" value="true" type="radio" name="visa_needed">
                        <span class="checkmark"></span>
                      </label>
                      <label class="checkbox-container form-check col-4 yes_no">{{$t('basicInformation.sectionTwo.no')}}
                        <input v-model="student.visa_needed.checked" value="false" type="radio" name="visa_needed">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>


                <div class="col-12 col-md-6 text-center-mobile col-xl-2"> 
                  <a href="/help" class="btn btn-primary btn-formulario btn-margin-extra btn-help" target="_blank"> 
                    {{$t('basicInformation.sectionTwo.button')}}
                  </a>
                </div>


              <div class="w-100 d-none d-xl-block"></div>


                <div class="col-12 col-md-6 offset-xl-1 col-xl-4">
                  <div class="form-group">
                    <label for="siblings" class="form-required">{{$t('basicInformation.sectionTwo.siblings')}}</label>
                    <div class="row">
                      <label class="checkbox-container form-check col-4 yes_no">{{$t('basicInformation.sectionTwo.yes')}}
                        <input v-model="student.siblings.enrolled" value="141180000" type="radio" name="siblings">
                        <span class="checkmark"></span>
                      </label>
                      <label class="checkbox-container form-check col-4 yes_no">{{$t('basicInformation.sectionTwo.no')}}
                        <input v-model="student.siblings.enrolled" value="141180001" type="radio" name="siblings">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>


                <div v-if="student.siblings.enrolled=='141180000'" class="col-12 col-md-6 col-xl-6">
                  <div class="form-group">
                    <label for="alumno-siblings" class="form-required">{{$t('basicInformation.sectionTwo.siblings_name')}}</label>
                    <input v-model="student.siblings.name" class="form-control" :class="{'input-blue':student.siblings.name!==null&&student.siblings.name!==''}" id="alumno-siblings">
                    
                    <div class="tooltip-error" v-if="formchecked && v$.student.siblings.name.required.$invalid">
                      <div class="arrow"></div>
                      <span class="tooltip-text">{{$t('errors.fieldRequired')}}</span>
                    </div>
                    
                  </div>
                </div>


              <div class="w-100 d-none d-xl-block"></div>

              <div class="col-12 col-md-12 offset-xl-1 col-xl-10">
                <div class="form-group">
                  <label for="student-mailcheck">
                    <input v-model="student.mailcheck" type="checkbox" id="student-mailcheck">                  
                    {{$t('basicInformation.sectionOne.mailcheck')}}
                  <span class="checkmark"></span>
                  </label>
                </div>
              </div>

              <div class="col-12 col-md-12 offset-xl-1 col-xl-10">
                 <div class="bloque-info-lines no-form "> 
                    <p class="bloque-info col-12"><i><sup>1</sup>{{$t('basicInformation.sectionTwo.maildescription')}}</i></p>
                 </div>
              </div>
                
                









                <div class="w-100"></div>
                <div class="col-12 text-center offset-md-3 col-md-6 offset-xl-4 col-xl-4"> 
                  <button type="submit" class="rounded-pill btn-lg btn-primary mb-2 form-btn-submit col-12">{{$t('basicInformation.buttons.save')}}</button>
                </div>
                <!-- <div class="col-12 text-center offset-md-3 col-md-6 offset-xl-4 col-xl-4"> 
                  <button type="submit" class="rounded-pill btn-lg btn-primary mb-2 form-btn-submit col-12" data-toggle="modal" data-target="#aceptar_condiciones" data-backdrop="static" data-keyboard="false">Guardar y continuar</button>
                </div> -->
              </div>
            </form>
          </div>
        </div>
      </div>


    <div v-if="photoKO" id="photo_ko" style="display:block;" class="modal fade show">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h5 class="modal-title">{{$t('modalphotoKO.title')}}</h5>
            <div class="divider-wrap col-12">
              <div class="divider-middle mx-auto"></div>
            </div>
            <p class="modal-text mx-auto">{{$t('modalphotoKO.subtitle')}}</p>
            <img src="@/assets/img/icon-error.svg" alt="" class="mx-auto icon-modal">
            <div class="col text-center"> 
              <button @click="closeModalphotoKO(false)" type="submit" class="btn btn-secondary btn-modal rounded-pill col-md-5">{{$t('modalphotoKO.button')}}</button>
              <button @click="closeModalphotoKO(true)" type="submit" class="btn btn-primary btn-modal rounded-pill col-md-5">{{$t('modalphotoKO.button1')}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>


      <ModalExpireToken v-if="expireToken"/>
      <ModalServiceError v-if="serviceError" :service-error="serviceError" @close-modal-error="modalErrorClose" :literal="serviceErrorMsg" />
      <ModalWorking v-if="showModalWorking" :show-modal-working="showModalWorking"/>

      <Footer/>
    </div>
  </template>

  <script>

  import { mapState } from 'vuex'
  import ModalExpireToken from '@/components/ModalExpireToken.vue'
  import ModalServiceError from '@/components/ModalServiceError.vue'
  import ModalWorking from '@/components/ModalWorking.vue'
  import HeaderBar from '@/components/HeaderBar.vue'
  import Header from '@/components/Header.vue'
  import Steps from '@/components/Steps.vue'
  import Footer from '@/components/Footer.vue'
  import useVuelidate from '@vuelidate/core'
  import { required, email, numeric, maxLength, requiredIf } from "@vuelidate/validators"
  import moment from 'moment'
  import shared from '@/shared'
  import ModalCropper from '@/components/ModalCropper.vue'




  const extension = (value) => {
   if (value==null || value=='') {
        return true;
    }
    else if (!value) {
        return false;
    }
    let re = /(\.jpg|\.jfif|\.ico|\.gif|\.svg|\.jpeg|\.webp|\.png|\.bmp|\.apng|\.avif)$/i;
    return (re.exec(value)) ? true : false;

  }

  const file_size_validation = (value) =>  {
        if (value==null) {
            return true;
        }
        else if (!value) {
            return false;
        }
        let file = value;
        return (file.length < 5242880 && file.length > 0);
 }

  export default {
    name: 'BasicInformation',
    components: {
      //ModalFirstConsent,
      ModalExpireToken,
      ModalServiceError,
      ModalWorking,
      HeaderBar,
      Header,
      Steps,
      Footer,
      ModalCropper
    },
    setup () {
      return {
        v$: useVuelidate()
      }
    },
    data() {
      return {
        student_photo_thumb:'',
        photoKO:false,
        addParent: false,
        has2Parent: false,
        firstParent: {
          legal_guardian: null,
          name: null,
          firstname: null,
          lastname: null,
          email: null,
          phone_number: null,
          country: null,
          province: null,
          tel_country_mec: null,
          tel_country_prefix: null,
          tel_country_name: null,
          city: null,
          tipo_familiar: 'propietario',
          how_met: null,
          how_met_other: null,
          current_nationality:{
            name: null,
            id: null,
            passport_type: null,
            passport_number: null,
            restricted: true,
            number_error: false
          },
          profession:null,
          participate: ''
        },
        secondParent: {
          legal_guardian: null,
          name: null,
          firstname: null,
          lastname: null,
          email: null,
          emailDuplicated: false,
          phone_number: null,
          country: null,
          province: null,
          tel_country_mec: null,
          tel_country_prefix: null,
          tel_country_name: null,
          city: null,
          tipo_familiar: 'segundo familiar',
          current_nationality:{
            name: null,
            id: null,
            passport_type: null,
            passport_number: null,
            restricted: true,
            number_error: false
          },
          profession:null
        },
        student: {
          name: null,
          firstname: null,
          lastname: null,
          mailcheck: false,
          passport_scan: {
            name: null,
            file: null,
            delete: false
          },
          date_of_birth: null,
          place_of_birth: null,
          date_of_birth_error: false,
          student_photo: {
            name: null,
            file: null,
            delete: false
          },
          country: null,
          province: null,
          city: null,
          academic_system: 
            {id: null,
            other: null},
          academic_year: 
          {
            name: null,
            id:null
          },  
          current_nationality1:{
            name: null,
            id: null,
            passport_type: null,
            passport_number: null,
            restricted: true,
            number_error: false
          },
          current_nationality2:{
            name: null,
            id: null,
            passport_type: null,
            passport_number: null,
            restricted: true,
            number_error: false
          },
          visa_needed: {
            checked: false,
            need_support: false
          },
          siblings:{
            enrolled:141180001,
            name: ''
          },
          interested: 141180000,
          gendercode: null,
          contactid: null,
          email: null,
          emailDuplicated: false
        },
        date_of_birth: {
          day: null,
          month: null,
          year: null
        },
        academic_system: {
          academic_name: null,
          systemstudent_id: null
        },
        countries: [],
        nationalities: [],
        academicYears: [],
        howMetOptions: [],
        dateOfBirth: new Date(),
        expireToken: false,
        serviceError: false,
        serviceErrorMsg: null,
        disableParentTwo: false,
        loggedParentTwo: false,
        showModalWorking: true,
        formchecked: false,
        childrenSel: [],
        provinces_student: [],
        provinces_firstParent: [],
        provinces_secondParent: [],
        imgSrc: '',
        imgSrcName: '',
        showModalCropper: false
      }
    },
    validations() {
      if(this.addParent) {
        return {
          firstParent: {
            legal_guardian: { required },
            name: { required, maxLength: maxLength(50)},
            firstname: { required, maxLength: maxLength(50) },
            lastname: { maxLength: maxLength(50) },
  //          email: { required, email },
            phone_number: { required, numeric, maxLength: maxLength(50) },
            country: { required },
            province: { required },
            how_met: { required },
            tel_country_mec: { required },
            city: { required, maxLength: maxLength(80) },
            current_nationality: {
              id: { required }
            },
            profession: { required }
          },
          secondParent: {
            legal_guardian: { required},
            name: { required, maxLength: maxLength(50) },
            firstname: { required, maxLength: maxLength(50) },
            lastname: { maxLength: maxLength(50) },
            email: { required, email, maxLength: maxLength(100) },
            phone_number: { required, numeric, maxLength: maxLength(50) },
            country: { required },
            province: { required },
            tel_country_mec: { required },
            city: { required, maxLength: maxLength(80) },
            profession: { required },
            current_nationality: {
              id: { required }
            },
          },
          student: {
            name: { required, maxLength: maxLength(50) },
            firstname: { required, maxLength: maxLength(50)},
            lastname: { maxLength: maxLength(50) },
  //          passport_scan: {
  //            name: {
  //              required,
  //              extension
  //            }
  //          },
            date_of_birth: { required },
            place_of_birth: { required },
            student_photo: {
              name: {
                extension
              },
              file:{
                file_size_validation
              }
            },
            country: { required },
            province: { required },
            city: { required, maxLength: maxLength(80) },
  //          academic_system: { 
  //            id: {required} 
  //          },
            academic_year: { 
              id : { required }
            },
            current_nationality1: {
              name: { required },
              id: { required },
              passport_type: { required },
              passport_number: { required, maxLength: maxLength(100) }
            },
            visa_needed: {
              checked: { required }
            },
            siblings: {
              name: { required: requiredIf(this.student.siblings && this.student.siblings.enrolled == '141180000') }
            },
            interested: { required },
            gendercode: { required },
            email: { required, email }
          }
        }
      } else {
        return {
          firstParent: {
            legal_guardian: { required },
            name: { required, maxLength: maxLength(50) },
            firstname: { required, maxLength: maxLength(50) },
            lastname: { maxLength: maxLength(50) },
  //          email: { required, email },
            phone_number: { required, numeric, maxLength: maxLength(50) },
            country: { required },
            province: { required },
            how_met: { required },
            tel_country_mec: { required },
            city: { required, maxLength: maxLength(80) },
            current_nationality: {
              id: { required }
            },
            profession: { required }

          },
          student: {
            name: { required, maxLength: maxLength(50) },
            firstname: { required, maxLength: maxLength(50) },
            lastname: { maxLength: maxLength(50) },
  //          passport_scan: {
  //            name: {
  //              required,
  //              extension
  //            }
  //          },
            date_of_birth: { required },
            place_of_birth: { required },
            student_photo: {
              name: {
                extension
              },
              file:{
                file_size_validation
              }
            },
            country: { required },
            province: { required },
            city: { required, maxLength: maxLength(80) },
  //          academic_system: { 
  //            id: {required} 
  //          },
            academic_year: { 
              id : { required }
            },
            current_nationality1: {
              name: { required, maxLength: maxLength(100) },
              passport_type: { required },
              passport_number: { required, maxLength: maxLength(100) },
              id: { required }
            },
            visa_needed: {
              checked: { required }
            },
            siblings: {
              name: { required: requiredIf(this.student.siblings && this.student.siblings.enrolled == '141180000') }
            },
            interested: { required },
            gendercode: { required },
            email: { required, email }
           }
        }
      }
    },
    computed: {
      ...mapState([
        'step',
        'msalConfig',
        'enviroments',
        'BasicInformation',
        'idAdmission',
        'lockApply'
      ]),

      nameAcademic() {
        return this.student.academic_system.academic_name;
      },
      account() {
        return Object.keys(this.msalConfig.account).length !== 0 ? this.msalConfig.account : undefined;
      },
      accessToken() {
        return Object.keys(this.msalConfig.accessToken).length !== 0 ? this.msalConfig.accessToken : undefined;
      }
    },
    watch: {

      dateOfBirth(newValue) {
        this.student.date_of_birth = moment(newValue).utcOffset(0, true).startOf('day').unix()
        this.splitDateOfBirth(moment(newValue).format('DD/MM/YYYY'))
      },

      "student.country"()
      {this.getProvince("student")},
      "firstParent.country"()
      {this.getProvince("firstParent")},
      "secondParent.country"()
      {this.getProvince("secondParent")},
      "student.siblings.enrolled"()
      {
        if (this.student.siblings.enrolled == '141180001')
        {this.student.siblings.name=''}
      },
      "imgSrcName"()
      {
        if( this.imgSrcName!=null && this.imgSrc.length < 5242880 && this.imgSrc.length > 23 && ( this.imgSrcName.toLowerCase().endsWith(".jpg") || this.imgSrcName.toLowerCase().endsWith(".jfif") || this.imgSrcName.toLowerCase().endsWith(".ico") || this.imgSrcName.toLowerCase().endsWith(".gif") || this.imgSrcName.toLowerCase().endsWith(".svg") || this.imgSrcName.toLowerCase().endsWith(".jpeg") || this.imgSrcName.toLowerCase().endsWith(".webp") || this.imgSrcName.toLowerCase().endsWith(".png") || this.imgSrcName.toLowerCase().endsWith(".bmp") || this.imgSrcName.toLowerCase().endsWith(".apng") || this.imgSrcName.toLowerCase().endsWith(".avif") ))
        {this.$refs.modal1Cropper.loadImg(this.imgSrc);}
        else if( this.imgSrcName!=null && this.imgSrc.length < 5242880 && this.imgSrc.length > 23)
        {   this.serviceError = true;
            this.serviceErrorMsg =  this.$t('errors.incorrectPhotoFormat');  }
        else if( this.imgSrcName!=null && (this.imgSrc.length > 5242879 || this.imgSrc.length < 24))
        {   this.serviceError = true;
            this.serviceErrorMsg =  this.$t('errors.fileSizeError2');  }    
      }



    },
    mounted() {

      this.normalize_name = shared.normalize_name;

      this.lockApply = false;
      this.$store.commit('setLock', false);

      window.scrollTo(0,0); 
      this.getBasicInformation();

      this.$store.watch(() => {
        return this.$store.state.BasicInformation.parent
      },(res) => {

        if(res === undefined) return;
        if(res.length === 2) {
          const orderParent = document.querySelector('#otro-parent');
          orderParent.classList.add("show");

          this.addParent = true;
          this.has2Parent = true;

          if (res[0].tipo_familiar == "propietario")
          {

            this.firstParent = res[0];
            this.secondParent = res[1];
          }
          else
          {
            this.firstParent = res[1];
            this.secondParent = res[0];
          }

          if ((this.secondParent.level_consent !== null) && (this.secondParent.level_consent !== "587030001"))
          {
            this.disableParentTwo = true;
          }

          if (this.secondParent.email == this.account.username)
          {
            this.loggedParentTwo = true;
            this.disableParentTwo = false;
          }
          else
          {this.loggedParentTwo = false;}



        } else {
          this.firstParent = res[0]
        }


if (!this.firstParent.current_nationality || this.firstParent.current_nationality==null)
{this.firstParent.current_nationality={
            name: null,
            id: null,
            passport_type: null,
            passport_number: null,
            restricted: true,
            number_error: false
          }}
if (!this.firstParent.current_nationality || this.firstParent.current_nationality==null)
{this.secondParent.current_nationality={
            name: null,
            id: null,
            passport_type: null,
            passport_number: null,
            restricted: true,
            number_error: false
          }}


      })
      this.$store.watch(() => {
        return this.$store.state.BasicInformation.student
      },(res) => {
        if(res === undefined) return
        if(Object.keys(res).length !== 0) {
          this.student = res;
          this.splitDateOfBirth(moment.unix(this.student.date_of_birth).format('DD/MM/YYYY'));
        }
      })
      this.getCountries();
      this.getNationalities();
      this.getAcademicYears();
      this.getHowMetOptions();
    },
    methods: {
      getBasicInformation() {

        if(this.idAdmission !== null){
          this.axios.get(`${this.enviroments.urlDev}/Student?token=${this.accessToken}&id_admision=${this.idAdmission}`).then(response => {
            
            this.showModalWorking = false;
            document.body.style.overflowY = "auto";

            if (response.data.status === 1) {
              this.serviceError = true;
              this.serviceErrorMsg =  response.data.message;
            } else if(response.data.status === 2) {
              this.expireToken = true;
            }
            else if(response.data.status === 0)
            {

              if (response.data.student.current_nationality1 === null)
              { response.data.student.current_nationality1 = {'name': null,'id': null,'passport_type': null,'passport_number': null,'restricted': true};}
              if (response.data.student.current_nationality2 === null)
              { response.data.student.current_nationality2 = {'name': null,'id': null,'passport_type': null,'passport_number': null,'restricted': true};}

              if (response.data.student.passport_scan === null)
              { response.data.student.passport_scan = {'name': null,'file': null,'delete': false};}

              if (!response.data.student.student_photo || response.data.student.student_photo.file == null )
              { response.data.student.student_photo = {'name': null,'file': null,'delete': false};}

              if (response.data.student.siblings === null || !response.data.student.siblings || response.data.student.siblings.enrolled == '')
              { response.data.student.siblings = {enrolled:141180001,name: ''};}


              if (response.data.student.academic_system === null)
              { response.data.student.academic_system = {'id': null,'other': null};}

              if (response.data.student.academic_year === null)
              { response.data.student.academic_year = {'id': null,'name': null};}

              if (response.data.student.visa_needed === null)
              { response.data.student.visa_needed = {'checked': false,'need_support': false};}

              if (response.data.student.student_photo.file != null && response.data.student.student_photo.file != '')
              { 
                this.student_photo_thumb = "data:image/jpeg;base64,"+response.data.student.student_photo.file;
                response.data.student.student_photo.name = 'phot_al_9.jpg';
              }


//              if (response.data.student.province == ''){response.data.student.province = 'N/A'}


  /*            if (response.data.firstParent.how_met_other !== "")
              { response.data.firstParent.how_met = 9999;}*/

              this.$store.commit('BasicInformation/setParent', response.data.parent)
              this.$store.commit('BasicInformation/setStudent', response.data.student)





            }

          })
          .catch( error => {
            console.log(error);
            this.serviceError = true;
            this.serviceErrorMsg =  `ERROR:: BASIC INFORMATION get Basic Information: ${error}`;
            this.showModalWorking = false;
            document.body.style.overflowY = "auto";
          });
        }
        else{

          // tomo la info del padre 1.
            this.axios.post(`${this.enviroments.urlDev}/Parent?token=${this.accessToken}`).then(response => {

            this.showModalWorking = false;
            document.body.style.overflowY = "auto";
            

            if (response.data.status === 1) {
              this.serviceError = true;
              this.serviceErrorMsg =  response.data.message;
            } else if(response.data.status === 2) {
              this.expireToken = true;
            }
            else if(response.data.status === 0){            
              if (response.data.parent_found !== null)
              {
                this.firstParent = response.data.parent_found;
//                if (this.firstParent.province == ''){this.firstParent.province = 'N/A'}

//QUITAR
if (!this.firstParent.current_nationality || this.firstParent.current_nationality==null)
{this.firstParent.current_nationality={
            name: null,
            id: null,
            passport_type: null,
            passport_number: null,
            restricted: true,
            number_error: false
          }
}



              }
            }

            })

          // tomo la informacion de los hijos procedentes formulario externo.
            this.axios.get(`${this.enviroments.urlDev}/SelectChildApplicationForm?token=${this.accessToken}`).then(response => {

            this.showModalWorking = false;
            document.body.style.overflowY = "auto";

            if (response.data.status === 1) {
              this.serviceError = true;
              this.serviceErrorMsg =  response.data.message;
            } else if(response.data.status === 2) {
              this.expireToken = true;
            }
            else if(response.data.status === 0){
              this.childrenSel = response.data.children_found;
            }

            })


        }
      },
      setBasicInformation(data) {
            this.showModalWorking = true;
            document.body.style.overflowY = "hidden";

        let idAdm = "";
        if(this.idAdmission !== null){
          idAdm = "&id_admision="+this.idAdmission;
        }      

        this.axios.post(`${this.enviroments.urlDev}/Student?token=${this.accessToken}`+idAdm,{student: this.student}).then(response => {
          if (response.data.status === 1) {
            this.showModalWorking = false;
            document.body.style.overflowY = "auto";
            this.serviceError = true;
            this.serviceErrorMsg =  response.data.message;
          } else if(response.data.status === 2) {
            this.showModalWorking = false;
            document.body.style.overflowY = "auto";
            this.expireToken = true;
          } else if(response.data.status === 0) {

            this.$store.commit('setIdAdmission', response.data.student.id_admision);

            //lanzamos la creacion de los 2 padres
            this.axios.all(data.map(value => this.axios.post(`${this.enviroments.urlDev}/Student?token=${this.accessToken}&id_admision=${this.idAdmission}`, value)))
            .then(this.axios.spread((...response) => {
              
              this.showModalWorking = false;

              if (response.some(elem => elem.data.status === 1)) {
                          

              this.serviceError = true;

                if (response[0].data.status === 1)
                  this.serviceErrorMsg =  response[0].data.message;
                if (response[1].data.status === 1)
                  this.serviceErrorMsg =  response[1].data.message;

              } else if(response.some(elem => elem.data.status === 2)) {
                this.$router.push({ name: 'Home', params: { param: 'expireToken' } });
                this.expireToken = true;
              } else if(response.every(elem => elem.data.status === 0)) {
                this.$router.push('academic-results');
                this.step.firstStep = false;

                this.step.step1Completed = true;
                
                if (this.student.student_photo.name != null && this.student.student_photo.name != '')
                {this.step.step1PhotoCompleted = true;}
                else
                {this.step.step1PhotoCompleted = false;}

                console.log(this.step.step1Completed);
                
              }
            }));




          }
        })
        .catch( error => {
          console.log(error);
            this.serviceError = true;
            this.serviceErrorMsg =  `ERROR:: BASIC INFORMATION set Student Information: ${error}`;
            this.showModalWorking = false;
            document.body.style.overflowY = "auto";
        });


        
      },
      loadFile(e, param) {

        let file = e.target.files[0]
        let reader = new FileReader()
               console.log("cargo");
        this.imgSrcName = null;



        reader.onload = (e) => {

          if(param === 'student_photo') {
              this.imgSrc = e.target.result;
              this.imgSrcName = file.name;

/*                this.student.student_photo = {
                  name: file.name,
                  delete: false,
                  file: e.target.result.replace("data:image/jpeg;base64,","").replace("data:application/pdf;base64,","")
                };*/

          }
        }

        reader.readAsDataURL(file);
      },
      resetFile(e, param) {
        console.log("reseteo");
        if(param === 'student_photo') {
            this.$refs.imageUploader.value = '';
/*            this.student.student_photo = {
              name: '',
              delete: false,
              file: ' '
            };*/
        }
      },
      scroll2error()
      {
        setTimeout(function () { if (document.getElementsByClassName('tooltip-error').length != 0) {document.getElementsByClassName('tooltip-error')[0].parentElement.scrollIntoView();}}.bind(this), 100)
      },
      saveAndContinue() {

        this.v$.$touch()
        this.formchecked = true;
      
        console.log (this.date_of_birth);

        //birth date checks
        if(moment(this.date_of_birth.year+"-"+this.date_of_birth.month+"-"+this.date_of_birth.day).isBefore('1900-1-1'))
        {this.student.date_of_birth_error = true;}
        else
        {this.student.date_of_birth_error = false;}

        //DNI checks
        this.student.current_nationality1.number_error=false;
        this.student.current_nationality2.number_error=false;
        
        if(this.student.current_nationality1.passport_number && this.student.current_nationality1.passport_type== '141180000')
          {this.student.current_nationality1.number_error = !this.checkDNI(this.student.current_nationality1.passport_number);}
        else if(this.student.current_nationality1.passport_number && this.student.current_nationality1.passport_type== '141180002')
          {this.student.current_nationality1.number_error = !this.checkNIE(this.student.current_nationality1.passport_number);}
        
        if(this.student.current_nationality2.passport_number && this.student.current_nationality2.passport_type== '141180000')
          {this.student.current_nationality2.number_error = !this.checkDNI(this.student.current_nationality2.passport_number);}
        else if(this.student.current_nationality2.passport_number && this.student.current_nationality2.passport_type== '141180002')
          {this.student.current_nationality2.number_error = !this.checkNIE(this.student.current_nationality2.passport_number);}
    
        if(this.v$.firstParent.$invalid || this.v$.student.$invalid) return;
        if(this.addParent && this.v$.secondParent.$invalid) return;

        //nationalities validations
        if( this.student.current_nationality2.id !== null && this.student.current_nationality2.id !== '0')
        {
          if (!this.student.current_nationality2.passport_number.trim() ) return;
          if (!this.student.current_nationality2.passport_type) return;
        } 
        if( this.student.current_nationality2.id !== null && this.student.current_nationality2.id === this.student.current_nationality1.id) return;
  //      if( this.student.current_nationality2.id !== null && this.student.current_nationality2.passport_number === this.student.current_nationality1.passport_number) return;
          if( this.student.current_nationality1.number_error || this.student.current_nationality2.number_error) return;

        //how_met validations
        if( this.firstParent.how_met == '141180015' && ( this.firstParent.how_met_other == null || this.firstParent.how_met_other == undefined || this.firstParent.how_met_other.trim() === '') ) return;
        if ((this.secondParent.emailDuplicated === true) || (this.student.emailDuplicated === true)) return;


        //birth date checks
        if(this.student.date_of_birth_error) return;

        if (this.student.current_nationality2.id === '0')  
        {
          this.student.current_nationality2.id = null;
          this.student.current_nationality2.name = null;
          this.student.current_nationality2.passport_number = null;
          this.student.current_nationality2.passport_type = null;
        }

        if (this.firstParent.how_met != '141180015')  
        {
          this.firstParent.how_met_other = null;
        }

        if(!this.loggedParentTwo)
          {this.firstParent.email = this.account.username;}


          const urlPost = [
            {
              parent: this.firstParent
            }
          ];
          
          if((this.addParent && !this.v$.secondParent.$invalid) || ( !this.addParent && this.has2Parent))
          {
            urlPost[1] = 
            {
              parent: this.secondParent
            };        
          }

          if(this.student.name!=null)
          {this.student.name= this.student.name.trim();}
          if(this.student.firstname!=null)
          {this.student.firstname= this.student.firstname.trim();}
          if(this.student.lastname!=null)
          {this.student.lastname= this.student.lastname.trim();}

          console.log(this.student.student_photo.name);

          if (this.student.student_photo.name != null && this.student.student_photo.name != '')
          { 
            this.student.student_photo.name = this.normalize_name(this.student.student_photo.name)
            this.setBasicInformation(urlPost);
          }
          else{
            if(!this.photoKO)
              {this.photoKO=true;}
            else
              {
                this.photoKO=false;
                this.setBasicInformation(urlPost);
              }
          }


      
      },
      splitDateOfBirth(value) {
        this.date_of_birth = {
          day: value.split('/')[0],
          month: value.split('/')[1],
          year: value.split('/')[2]
        }
      },
      updateStudentBirth()
      {
        if ((this.date_of_birth.day!=null)&&(this.date_of_birth.month!=null)&&(this.date_of_birth.year)){
          this.student.date_of_birth = moment.utc(this.date_of_birth.day+"/"+this.date_of_birth.month+"/"+this.date_of_birth.year,"DD/MM/YYYY").startOf('day').unix()
        }
      },
      getCountries() {

        let langParam = "";
        if (this.$i18n.locale == 'es')
        {langParam = "&idioma=es";}

        this.axios.get(`${this.enviroments.urlDev}/Country?token=${this.accessToken}${langParam}`).then(response => {
          if (response.data.status === 1) {
            this.serviceError = true;
            this.serviceErrorMsg =  response.data.message;
          } else if(response.data.status === 2) {
            this.expireToken = true;
          }
          else if(response.data.status === 0){
            this.countries = response.data.countries_matches;
          }
        })
        .catch( error => {
          console.log(error);
          this.serviceError = true;
          this.serviceErrorMsg =  `ERROR:: BASIC INFORMATION get Countries: ${error}`;
        });
      },
      getNationalities() {
        let langParam = "";
        if (this.$i18n.locale == 'es')
        {langParam = "&idioma=es";}

        this.axios.get(`${this.enviroments.urlDev}/Nationalities?token=${this.accessToken}${langParam}`).then(response => {
          if (response.data.status === 1) {
            this.serviceError = true;
            this.serviceErrorMsg =  response.data.message;
          } else if(response.data.status === 2) {
            this.expireToken = true;
          }
          else if(response.data.status === 0){
            this.nationalities = response.data.nationalities_matches;
          }
        })
        .catch( error => {
          console.log(error);
          this.serviceError = true;
          this.serviceErrorMsg =  `ERROR:: BASIC INFORMATION get Nationalities: ${error}`;
        });
      },
      getAcademicYears() {

        this.axios.get(`${this.enviroments.urlDev}/AcademicYear?token=${this.accessToken}`).then(response => {
          if (response.data.status === 1) {
            this.serviceError = true;
            this.serviceErrorMsg =  response.data.message;
          } else if(response.data.status === 2) {
            this.expireToken = true;
          }
          else if(response.data.status === 0){
            this.academicYears = response.data.academic_years;
          }
        })
        .catch( error => {
          console.log(error);
          this.serviceError = true;
          this.serviceErrorMsg =  `ERROR:: BASIC INFORMATION get Academic Years: ${error}`;
        });
      },
      getHowMetOptions() {
        let langParam = "";
        if (this.$i18n.locale == 'es')
        {langParam = "&idioma=es";}

        this.axios.get(`${this.enviroments.urlDev}/OptionSet?token=${this.accessToken}${langParam}`).then(response => {
          if (response.data.status === 1) {
            this.serviceError = true;
            this.serviceErrorMsg =  response.data.message;
          } else if(response.data.status === 2) {
            this.expireToken = true;
          }
          else if(response.data.status === 0){
            this.howMetOptions = response.data.results;
          }
        })
        .catch( error => {
          console.log(error);
          this.serviceError = true;
          this.serviceErrorMsg =  `ERROR:: BASIC INFORMATION get How Met Options: ${error}`;
        });
      },    

      selectCountry1(e) {
        console.log(e.target.options[e.target.options.selectedIndex].dataset.prefix);

         if(e.target.options.selectedIndex > -1) {
            this.firstParent.tel_country_prefix = e.target.options[e.target.options.selectedIndex].dataset.prefix;
            this.firstParent.tel_country_name = e.target.options[e.target.options.selectedIndex].dataset.name;
        }

        //let exists = this.countries.find(p => p.phone_code == this.firstParent.tel_country)
        //if(exists !== -1) { 
        //  this.firstParent.tel_country_name = exists.nombre;
        //}
      },
      selectCountry2(e) {
         if(e.target.options.selectedIndex > -1) {
            this.secondParent.tel_country_prefix = e.target.options[e.target.options.selectedIndex].dataset.prefix;
            this.secondParent.tel_country_name = e.target.options[e.target.options.selectedIndex].dataset.name;
        }

//        let exists = this.countries.find(p => p.phone_code == this.secondParent.tel_country)
//        if(exists !== -1) { 
//          this.secondParent.tel_country_name = exists.nombre;
//        }
      },
      selectNationality1(nationality) {
      
        let exists = this.nationalities.find(p => p.nationalityid == nationality.target.value)
        if(exists !== -1) { 
          this.student.current_nationality1.id = exists['nationalityid'];
          this.student.current_nationality1.name = exists['name'];
          this.student.current_nationality1.restricted = exists['restricted'];
        }

        if (this.student.current_nationality1.restricted)
          {this.student.current_nationality1.passport_type = '141180001'}
        else
          {this.student.current_nationality1.passport_type = '141180000'} 

      },
      selectNationality2(nationality) {
      
        let exists = this.nationalities.find(p => p.nationalityid == nationality.target.value)
        if(exists) { 
          console.log(exists);
          this.student.current_nationality2.id = exists['nationalityid'];
          this.student.current_nationality2.name = exists['name'];
          this.student.current_nationality2.restricted = exists['restricted'];

          if (this.student.current_nationality2.restricted)
            {this.student.current_nationality2.passport_type = '141180001'}
          else
            {this.student.current_nationality2.passport_type = '141180000'}
        }
        else
        {
                  console.log("no exists");
          this.student.current_nationality2.passport_number = null;
          this.student.current_nationality2.passport_type = null;
        }
  
      },
      getProvince(target)
      {
          let queryParam = "idioma=en";
          if (this.$i18n.locale == 'es')
            {queryParam = "idioma=es";}

          if ((target == "student") && (this.student.country !=null) && (this.student.country!=''))
          { queryParam += "&mec_code="+this.student.country; }
          else if ((target == "firstParent") && (this.firstParent.country!=null) && (this.firstParent.country!=''))
          { queryParam += "&mec_code="+this.firstParent.country; }
          else if ((target == "secondParent") && (this.secondParent.country!=null) && (this.secondParent.country!=''))
          { queryParam += "&mec_code="+this.secondParent.country;}
          else
          { return false}
          

          this.axios.get(`${this.enviroments.urlDev}/Province?${queryParam}`).then(response => {
          if (response.data.status === 1) {
            this.showModalLoading = false;
            document.body.style.overflowY = "auto";

            this.serviceError = true;
            this.serviceErrorMsg =  response.data.message;
            this.serviceErrorButton = true;

          }
          else if(response.data.status === 0){
            if (response.data.province_matches[0].name != "N/A")
            {
              var valObj = "";

              if (target == "student")
              {
                this.provinces_student = response.data.province_matches;

                var curr_student_prov =  this.student.province;
                valObj = this.provinces_student.filter(function(elem){
                if(elem.id == curr_student_prov) return elem;
                });

                if(valObj.length == 0)
                { this.student.province = null;}

              }
              else if (target == "firstParent")
              {
                this.provinces_firstParent = response.data.province_matches;

                var curr_firstParent_prov =  this.firstParent.province;
                valObj = this.provinces_firstParent.filter(function(elem){
                if(elem.id == curr_firstParent_prov) return elem;
                });

                if(valObj.length == 0)
                { this.firstParent.province = null;}

              }
              else if (target == "secondParent")
              {
                this.provinces_secondParent = response.data.province_matches;

                var curr_secondParent_prov =  this.secondParent.province;
                valObj = this.provinces_secondParent.filter(function(elem){
                if(elem.id == curr_secondParent_prov) return elem;
                });

                if(valObj.length == 0)
                { this.secondParent.province = null;}

              }
            }
            else{
              if (target == "student")
              { this.provinces_student = response.data.province_matches;
                this.student.province = response.data.province_matches[0].id;}
              else if (target == "firstParent")
               {this.provinces_firstParent = response.data.province_matches;
                this.firstParent.province = response.data.province_matches[0].id;}
              else if (target == "secondParent")
               {this.provinces_secondParent = response.data.province_matches;
                this.secondParent.province = response.data.province_matches[0].id;}
            }

          }
        })
        .catch( error => {
          console.log(error);
          this.serviceError = true;
          this.serviceErrorMsg =  `ERROR:: BASIC INFORMATION get Provinces: ${error}`;
          this.serviceErrorButton = true;

        });
      },
      deleteSecondParent() {
        const orderParent = document.querySelector('#otro-parent');
        orderParent.classList.remove("show");
        this.addParent = false;
        this.disableParentTwo = false;
        this.secondParent = {
          legal_guardian: null,
          name: null,
          firstname: null,
          lastname: null,
          email: null,
          phone_number: null,
          country: null,
          tel_country_mec: null,
          tel_country_prefix: null,
          tel_country_name: null,
          city: null,
          province: null,
          profession:null,
          current_nationality:{
            name: null,
            id: null,
            passport_type: null,
            passport_number: null,
            restricted: true,
            number_error: false
          }
        }
      },
      changeChild(event)
      {
          if (event.target.value != 0)
          {
            var valObj = this.childrenSel.filter(function(elem){
            if(elem.contactId == event.target.value) return elem;
            });

            if(valObj.length > 0)
            {

              if (valObj[0].current_nationality1 === null || valObj[0].current_nationality1.id === "")
              { valObj[0].current_nationality1 = {'name': null,'id': null,'passport_type': null,'passport_number': null,'restricted': true};}

              if (valObj[0].academic_yearid === null)
              { valObj[0].academic_year = {'id': null,'name': null};}

              if (valObj[0].interested === "")
              { valObj[0].interested = 141180000;}

              if (valObj[0].gendercode === "")
              { valObj[0].gendercode = 1;}


                this.student.contactid = event.target.value
                this.student.name = valObj[0].name
                this.student.firstname = valObj[0].firstname
                this.student.lastname = valObj[0].lastname
                this.student.place_of_birth = valObj[0].place_of_birth
                if (valObj[0].date_of_birth !='')
                {
                    this.student.date_of_birth = valObj[0].date_of_birth;
                    this.splitDateOfBirth(moment.unix(valObj[0].date_of_birth).format('DD/MM/YYYY'));
                }
                this.student.country = valObj[0].country
                this.student.academic_year.id = valObj[0].academic_yearid
                this.student.current_nationality1 = valObj[0].current_nationality1
                this.student.current_nationality2 = {'name': null,'id': null,'passport_type': null,'passport_number': null,'restricted': true};
                this.student.gendercode = valObj[0].gendercode
                this.student.interested = valObj[0].interested
                this.student.email = valObj[0].email
                this.student.province = valObj[0].province

            }
          }
          else
          {

                this.student.contactid = null
                this.student.name = null
                this.student.firstname = null
                this.student.lastname = null
                this.student.date_of_birth = null;
                this.splitDateOfBirth('00/00/0000');
                this.student.place_of_birth = null
                this.student.country = null
                this.student.academic_year = {'id': null,'name': null};
                this.student.current_nationality1 = {'name': null,'id': null,'passport_type': null,'passport_number': null,'restricted': true};
                this.student.current_nationality2 = {'name': null,'id': 0,'passport_type': null,'passport_number': null,'restricted': true};
                this.student.gendercode = 1
                this.student.visa_needed = {'checked': false,'need_support': false};
                this.student.interested = 141180000
                this.student.email = null
                this.student.province = null
                this.student.passport_scan = {'name': null,'file': null,'delete': false};
                this.student.student_photo = {'name': null,'file': null,'delete': false};
                this.student.academic_system = {'id': null,'other': null};

          }


      },
      modalErrorClose()
      {this.serviceError=false},
      checkEmailStudent() {
        this.student.emailDuplicated = false;
        if ((this.student.email != null) && (this.student.email != '') && ((this.student.email.toLowerCase() === this.firstParent.email.toLowerCase() ) || (this.student.email.toLowerCase() === this.secondParent.email.toLowerCase() )))
        {
          this.student.emailDuplicated = true;
        }
      },
      checkEmailParentTwo() {
  //      const status = 1;
  //      if(status === 1) {
          this.disableParentTwo = false;
  //      } else {
  //        this.disableParentTwo = true;
  //      }


        if ((this.secondParent.email != null) && (this.secondParent.email != '') && ((this.secondParent.email.toLowerCase() === this.firstParent.email.toLowerCase() ) ))
        {
          this.secondParent.emailDuplicated = true;
          return;
        }
        else if ((this.secondParent.email != null) && (this.secondParent.email != '') && (this.student.email != null) && (this.student.email != '') && ((this.secondParent.email.toLowerCase() === this.student.email.toLowerCase() )))
        {
          this.secondParent.emailDuplicated = true;
          return;
        }

        else
        {
          this.secondParent.emailDuplicated = false;
        }
        
        this.axios.get(`${this.enviroments.urlDev}/Parent?token=${this.accessToken}&cadena=${this.secondParent.email}`).then(response => {
        
          if (response.data.status === 1) {
            this.serviceError = true;
            this.serviceErrorMsg =  response.data.message;
          } else if(response.data.status === 2) {
            this.expireToken = true;
          }
          else if(response.data.status === 0){

            if (response.data.parent_found !== null)
            {
            this.secondParent = response.data.parent_found;
            this.secondParent.tipo_familiar= 'segundo familiar';
//            if (this.secondParent.province == ''){this.secondParent.province = 'N/A'}


            if ((response.data.parent_found.level_consent !== null) && (response.data.parent_found.level_consent !== "587030001"))
              {this.disableParentTwo = true;} 
            }
          }

        })
        .catch( error => {
          console.log(error);
          this.serviceError = true;
          this.serviceErrorMsg =  `ERROR:: BASIC INFORMATION get Second Parent: ${error}`;
        });
      },
      checkDNI(value)
      {
        const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;

        var str = value.toString().toUpperCase();
        str = str.trim();
        str = str.replace(/\s/g, ""); //Y se quita los espacios en blanco dentro de la cadena

        var dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
        var letter_ = dni_letters.charAt( parseInt( str, 10 ) % 23 );   
  
        if (nifRexp.test(str) && (letter_ == str.charAt(8)))
        { return true}
        else
        { return false}

      },
      checkNIE(value)
      {
        const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
        const nieRexp = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;

        var str = value.toString().toUpperCase();
        str = str.trim();
        str = str.replace(/\s/g, ""); //Y se quita los espacios en blanco dentro de la cadena

        var nie = str.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2');
        var letter = str.substr(-1);
        var charIndex = parseInt(nie.substr(0, 8)) % 23;
        if (nieRexp.test(str) && (validChars.charAt(charIndex) === letter) )
        { return true}
        else
        { return false}

      },

      imagecrppd (value) {
          this.student_photo_thumb = value;
          this.student.student_photo.file = value;
          this.student.student_photo.name = this.imgSrcName;
      },

      closeModalphotoKO(value)
      {
        if(value==true)
        {this.saveAndContinue();}
        else
        {this.photoKO=false;}
      }


    }
  }
  </script>
